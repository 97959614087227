<template>
  <th
    v-if="isHeader"
    v-html="title"
  />

  <td v-else>
    <span :class="['switch', { 'switch-success': notificationPreferences && notificationPreferences.isNotificationPreferenceEnabled(type) }]">
      <label>
        <input
          type="checkbox"
          :checked="notificationPreferences && notificationPreferences.isNotificationPreferenceEnabled(type)"
          @change.prevent="setNotificationType(type, $event.target.checked)"
          name="select"
        >
        <span />
      </label>
    </span>
  </td>
</template>

<script>
import VuetableFieldMixin from '@bartversluijs/vuetable-2/src/components/VuetableFieldMixin.vue';
import NotificationPreferences from '@/libs/classes/notification_preferences';
import companyLib from '@vedicium/core-vue/build/modules/company';

export default {
  name: 'notificationField',
  mixins: [VuetableFieldMixin],
  data () {
    return {
      notifications: (this.rowField && this.rowField.notifications),
      notificationPreferences: this.rowData,
      type: (this.rowField && this.rowField.type),
    };
  },
  watch: {
    rowData (row) {
      this.$set(this, 'notificationPreferences', row);
    },
  },

  methods: {
    async setNotificationType (type, enabled) {
      if (!this.notificationPreferences || !type || typeof enabled !== 'boolean') {
        return;
      }

      // Set notification preference for type
      this.notificationPreferences.setNotificationPreference(type, this.notifications.methods, enabled);

      // Set sync disabled, because we've changed something
      (this.notifications.types || []).forEach((notificationTypes) => {
        this.notificationPreferences.setSync(notificationTypes, this.notifications.methods, false);
      });

      this.vuetable.$parent.setLoading(true);
      try {
        await this.$ws.put(`${NotificationPreferences.uri}/companies/${companyLib.current._meta.guid}/users/${this.notificationPreferences.user_guid}`, {
          body: {
            notification_preferences: this.notificationPreferences.notification_preferences,
          },
        });

        await this.vuetable.$parent.reload({ query: { disable_cache: true } });
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.vuetable.$parent.setLoading(false);
      }
    },
  },
};
</script>
