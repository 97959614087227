<template>
  <div class="row">
    <div class="col">
      <div class="card card-custom">
        <!-- begin::Content -->
        <div class="card-body min-h-100px">
          <!-- begin::Datatable actions -->
          <div class="mb-7">
            <div class="row align-items-center">
              <div class="col-12 col-lg">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    @keyup.enter="$refs.datatable.search"
                    @keyup="searchDebounced"
                  >
                  <span>
                    <i>
                      <font-awesome-icon :icon="['fas', 'search']" />
                    </i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end::Datatable actions -->

          <!-- begin::Datatable -->
          <datatable
            ref="datatable"
            :url="datatable.url"
            :fields="datatable.fields"
            :search-fields="datatable.searchFields"
            :on-pagination-data="onPaginationData"

            :filters="datatable.filters"

            :transform="transform"
          >
            <!-- begin::Switch - Default -->
            <div slot="switch.default" slot-scope="props">
              <span :class="['switch', { 'switch-success': props.rowData.isInSync() }]">
                <label>
                  <input
                    type="checkbox"
                    :checked="props.rowData.isInSync()"
                    @change.prevent="setSync(props.rowData, $event.target.checked)"
                    name="select"
                  >
                  <span />
                </label>
              </span>
            </div>
            <!-- end::Switch - Default -->
          </datatable>
          <!-- end::Datatable -->

          <!-- begin::Datatable footer -->
          <div class="datatable datatable-default">
            <div class="datatable-pager datatable-paging-loaded">
              <datatable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              />

              <datatable-pagination-info
                ref="pagination.info"
                @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              />
            </div>
          </div>
          <!-- end::Datatable footer -->
        </div>
        <!-- end::Content -->
      </div>
    </div>
  </div>
</template>

<script>
// Mixins
import { DatatableMixin } from '@vedicium/metronic-vue';

// Libraries
import NotificationPreferences from '@/libs/classes/notification_preferences';
import companyLib from '@vedicium/core-vue/build/modules/company';

// Components
import notificationField from './components/notification.field.vue';

export default {
  mixins: [DatatableMixin],
  props: {
    notifications: {
      type: Object,
      default: () => ({
        types: [],
        methods: [],
      }),
    },
  },
  data () {
    // Create all datatable fields
    const datatableFields = [{
      name: 'user.email',
      title: 'Email address',
      sortField: 'user.email',
      titleClass: 'w-300px',
      dataClass: 'w-300px',
    }, {
      name: 'switch.default',
      title: 'Default',
      titleClass: 'w-125px',
      dataClass: 'w-125px',
    }];

    // Add fields based on types
    if (this.notifications.types && this.notifications.types.length) {
      this.notifications.types.forEach((type) => {
        datatableFields.push({
          name: notificationField,
          title: this.$tu(`notifications.${type}.title`) || type,
          type,
          notifications: this.notifications,
          titleClass: 'w-125px',
          dataClass: 'w-125px',
        });
      });
    }

    return {
      datatable: {
        url: `${NotificationPreferences.uri}/companies/${companyLib.current._meta.guid}/users`,

        fields: datatableFields,
        searchFields: ['user.email'],
      },
    };
  },

  methods: {
    transform (data) {
      const response = { ...data };
      if (response.data && Array.isArray(response.data)) {
        response.data = response.data.map((row) => new NotificationPreferences().merge(row));
      }
      return response;
    },

    async setSync (notificationPreferences, enabled) {
      if (!notificationPreferences || typeof enabled !== 'boolean') {
        return;
      }

      this.$refs.datatable.setLoading(true);
      try {
        // Set sync to false for every type
        (this.notifications.types || []).forEach((type) => {
          notificationPreferences.setSync(type, this.notifications.methods, enabled);
        });

        await this.$ws.put(`${NotificationPreferences.uri}/companies/${companyLib.current._meta.guid}/users/${notificationPreferences.user_guid}`, {
          body: {
            notification_preferences: notificationPreferences.notification_preferences,
          },
        });

        await this.$refs.datatable.reload({ query: { disable_cache: true } });
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$refs.datatable.setLoading(false);
      }
    },
  },
};
</script>
